<template>
  <div class="container">
    <h3>SI ME CONOCEN, MEJOR</h3>
    <h5>
      Estas preguntas permitirán acompañarte en la construcción de tu proyecto
      personal y profesional. Nuestro compromiso es utilizar esta información,
      sólo para fines académicos y formativos. Una vez terminado de contestar,
      aparecerá el botón <strong>Enviar</strong> que debes presionar para que
      tus respuestas sean guardadas.
    </h5>
    <hr />
    <div class="section">
      <strong>I-</strong> Tus Antecedentes
      <div class="subsection">
        <strong>I. a - Personales.</strong>
        <div class="row my-1 mt-2">
          <div class="col">1. Tipo de institución de egreso (Cuarto Medio)</div>
          <div class="col">
            <b-form-select
              :options="college_types"
              v-model="$v.social.college_type.$model"
              value-field="id"
              text-field="value"
              size="sm"
              class="m-0 mb-2"
              :class="{ 'input--error': $v.social.$error }"
              :disabled="!allows_crud"
              aria-describedby="college_type-feedback"
              :state="validateState('college_type')"
            ></b-form-select>
            <template v-if="allows_crud">
              <b-form-invalid-feedback id="college_type-feedback"
                >Debe seleccionar una opción.</b-form-invalid-feedback
              >
            </template>
          </div>
        </div>
        <div class="row my-1">
          <div class="col mt-2">
            2. Lugar donde se ubica tu institución de egreso
          </div>
          <div class="col">
            <b-form-group
              label="Región:"
              label-cols="0"
              label-cols-md="2"
              label-for="college_region"
              class="p-0 m-0"
            >
              <b-form-select
                id="college_region"
                :options="regions"
                value-field="id"
                text-field="name"
                v-model="$v.social.college_region.$model"
                :disabled="!allows_crud"
                aria-describedby="college_region-feedback"
                :state="validateState('college_region')"
                size="sm"
                class="ml-1"
                :class="{ 'input--error': $v.social.$error }"
              ></b-form-select>
              <template v-if="allows_crud">
                <b-form-invalid-feedback id="college_region-feedback"
                  >Debe seleccionar una opción.</b-form-invalid-feedback
                >
              </template>
            </b-form-group>
            <b-form-group
              label="Comuna:"
              label-cols="0"
              label-cols-md="2"
              label-for="college_commune"
              class="p-0 m-0 mb-1"
            >
              <b-form-select
                id="college_commune"
                v-model="$v.social.college_commune.$model"
                :options="filteredCommunes"
                value-field="id"
                text-field="name"
                :disabled="!allows_crud"
                aria-describedby="college_commune-feedback"
                :state="validateState('college_commune')"
                size="sm"
                class="mb-1 ml-1"
                :class="{ 'input--error': $v.social.$error }"
              ></b-form-select>
              <template v-if="allows_crud">
                <b-form-invalid-feedback id="college_commune-feedback"
                  >Debe seleccionar una opción.</b-form-invalid-feedback
                >
              </template>
            </b-form-group>
          </div>
        </div>
        <div class="row my-1">
          <div class="col mt-1">
            3. ¿Es tu primer ingreso a la educación superior?
          </div>
          <div class="col mt-3">
            <div class="row" :class="{ 'input--error': $v.social.$error }">
              <b-form-checkbox
                v-model="social.is_firt_time_university"
                :checked="social.is_firt_time_university"
                :state="validateState('is_firt_time_university')"
                :value="true"
                :disabled="!allows_crud"
                class="mr-2 ml-3"
                >Si</b-form-checkbox
              >
              <b-form-checkbox
                v-model="social.is_firt_time_university"
                :checked="!social.is_firt_time_university"
                :value="false"
                :disabled="!allows_crud"
                >No</b-form-checkbox
              >
            </div>
          </div>
        </div>
        <div class="row my-1">
          <div class="col mt-1">
            4. ¿Estudiabas antes en esta Universidad y reingresas?
          </div>
          <div class="col mt-3">
            <div class="row" :class="{ 'input--error': $v.social.$error }">
              <b-form-checkbox
                v-model="social.is_re_entry"
                :checked="social.is_re_entry"
                :state="validateState('is_re_entry')"
                :value="true"
                :disabled="!allows_crud"
                class="mr-2 ml-3"
                >Si</b-form-checkbox
              >
              <b-form-checkbox
                v-model="social.is_re_entry"
                :checked="!social.is_re_entry"
                :value="false"
                :disabled="!allows_crud"
                >No</b-form-checkbox
              >
            </div>
          </div>
        </div>
        <div class="row my-1">
          <div class="col mt-1">
            5. ¿Vienes de otra institución de formación superior?
          </div>
          <div class="col mt-3">
            <div class="row">
              <b-form-checkbox
                v-model="social.is_from_other_university"
                :checked="social.is_from_other_university"
                :state="validateState('is_from_other_university')"
                :value="true"
                :disabled="!allows_crud"
                class="mr-2 ml-3"
                >Si</b-form-checkbox
              >
              <b-form-checkbox
                v-model="social.is_from_other_university"
                :checked="!social.is_from_other_university"
                :value="false"
                :disabled="!allows_crud"
                >No</b-form-checkbox
              >
            </div>
          </div>
        </div>
        <!-- 6. Especifica de cuál institución superior vienes -->
        <div v-if="social.is_from_other_university == true" class="row my-1">
          <div class="col mt-1">
            6. Especifica de cuál institución superior vienes
          </div>
          <div class="col mt-1">
            <b-form-select
              v-model="$v.social.other_university_name.$model"
              :options="other_institutions"
              value-field="id"
              text-field="name"
              :disabled="!allows_crud"
              size="sm"
            ></b-form-select>
          </div>
        </div>
        <div v-else class="row my-1">
          <div class="col mt-1">
            6. Especifica de cuál institución superior vienes
          </div>
          <div class="col mt-1">
            <b-form-select
              v-model="$v.social.other_university_name.$model"
              :options="other_institutions"
              value-field="id"
              text-field="name"
              disabled
              size="sm"
            ></b-form-select>
          </div>
        </div>
        <div class="row my-1">
          <div class="col mt-1">
            ¿Estudiabas la misma carrera anteriormente?
          </div>
          <div class="col mt-3">
            <div class="row">
              <b-form-checkbox
                v-model="social.is_same_career"
                :checked="social.is_same_career"
                :value="true"
                :state="validateState('is_same_career')"
                :disabled="!allows_crud"
                class="mr-2 ml-3"
                >Si</b-form-checkbox
              >
              <b-form-checkbox
                v-model="social.is_same_career"
                :checked="!social.is_same_career"
                :value="false"
                :disabled="!allows_crud"
                >No</b-form-checkbox
              >
            </div>
          </div>
        </div>
        <div class="row my-1">
          <div class="col mt-1">
            ¿Estudiabas otra carrera diferente anteriormente?
          </div>
          <div class="col mt-3">
            <div class="row">
              <b-form-checkbox
                v-model="social.other_career_before"
                :checked="social.other_career_before"
                :state="validateState('other_career_before')"
                :value="true"
                :disabled="!allows_crud"
                class="mr-2 ml-3"
                >Si</b-form-checkbox
              >
              <b-form-checkbox
                v-model="social.other_career_before"
                :checked="!social.other_career_before"
                :value="false"
                :disabled="!allows_crud"
                >No</b-form-checkbox
              >
            </div>
          </div>
        </div>
        <!-- 7. ¿Cuál es su nacionalidad? -->
        <div class="row my-1">
          <div class="col mt-2">7. ¿Cuál es su nacionalidad?</div>
          <div class="col mt-2">
            <multiselect
              :options="nationalities"
              v-model="selected_nationalities"
              placeholder="Escriba aquí"
              label="name"
              track-by="id"
              size="sm"
              multiple
              selectLabel="Enter para seleccionar"
              selectedLabel="Seleccionado"
              :disabled="!allows_crud"
              :class="{ 'input--error': $v.social.$error }"
            ></multiselect>
            <template v-if="selected_nationalities.length == 0">
              <b-form-text text-variant="danger"
                >Debe seleccionar una Nacionalidad.</b-form-text
              >
            </template>
          </div>
        </div>
        <!-- 8. ¿En qué idiomas puede tener una conversación? -->
        <div class="row my-1">
          <div class="col mt-1">
            8. ¿En qué idiomas puede tener una conversación?
          </div>
          <div class="col">
            <multiselect
              :options="languages"
              v-model="selected_languages"
              placeholder="Escriba aquí"
              label="name"
              track-by="id"
              size="sm"
              class="m-0 p-0"
              multiple
              selectLabel="Enter para seleccionar"
              selectedLabel="Seleccionado"
              :disabled="!allows_crud"
            ></multiselect>
            <template v-if="selected_languages.length == 0">
              <b-form-text text-variant="danger"
                >Debe seleccionar un Idioma.</b-form-text
              >
            </template>
          </div>
        </div>
        <!-- 9. ¿Perteneces o eres descendiente de algún pueblo indígena (originario)? -->
        <div class="row my-1">
          <div class="col mt-1">
            9. ¿Perteneces o eres descendiente de algún pueblo indígena
            (originario)?
          </div>
          <div class="col">
            <multiselect
              :options="ethnicities"
              v-model="selected_ethnicities"
              placeholder="Escriba aquí"
              label="name"
              track-by="id"
              size="sm"
              class="m-0 p-0"
              multiple
              selectLabel="Enter para seleccionar"
              selectedLabel="Seleccionado"
              :disabled="!allows_crud"
            ></multiselect>
          </div>
        </div>
        <!-- 10. ¿Hablas o entiendes alguna(s) de las siguientes lenguas? -->
        <div class="row my-1">
          <div class="col" style="max-width: 50% !important">
            10. ¿Hablas o entiendes alguna(s) de las siguientes lenguas?
          </div>
          <div class="col">
            <NativeLanguages
              :Social="social"
              :Ethnicities="ethnicities"
              :allows_crud="allows_crud"
              @updated="slotUpdatedNativeLanguages"
            ></NativeLanguages>
          </div>
        </div>
      </div>
      <!-- I. b - Familiares. -->
      <div class="subsection">
        <strong>I. b - Familiares.</strong>
        <div class="row my-1">
          <!-- 1. Nivel educacional de la madre -->
          <div class="col mt-1">1. Nivel educacional de la madre</div>
          <div class="col mt-1">
            <div class="d-flex flex-row">
              <b-form-select
                v-model="$v.social.mother_educational.$model"
                :options="educational_types"
                value-field="id"
                text-field="value"
                size="sm"
                class="m-0 w-50 mr-4"
                :class="{ 'input--error': $v.social.$error }"
                :disabled="!allows_crud"
                aria-describedby="mother_educational-feedback"
                :state="validateState('mother_educational')"
              ></b-form-select>
              <b-form-checkbox
                v-for="ec in educational_completation_types"
                :key="ec.id"
                v-model="social.mother_completation"
                :disabled="!allows_crud"
                :value="ec.id"
                class="mx-1"
              >
                {{ ec.value }}</b-form-checkbox
              >
            </div>
            <template v-if="!social.mother_educational">
              <b-form-text text-variant="danger"
                >Debe seleccionar un Nivel Educacional.</b-form-text
              >
            </template>
            <template v-if="!social.mother_completation">
              <b-form-text text-variant="danger"
                >Debe seleccionar una opción.</b-form-text
              >
            </template>
          </div>
        </div>

        <div class="row my-1">
          <!-- 2. Nivel educacional del padre -->
          <div class="col mt-1">2. Nivel educacional del padre</div>
          <div class="col mt-1">
            <div class="d-flex flex-row">
              <b-form-select
                :options="educational_types"
                v-model="$v.social.father_educational.$model"
                value-field="id"
                text-field="value"
                size="sm"
                class="m-0 w-50 mr-4"
                :disabled="!allows_crud"
                aria-describedby="father_educational-feedback"
                :state="validateState('father_educational')"
              ></b-form-select>
              <b-form-checkbox
                v-for="ec in educational_completation_types"
                :key="ec.id"
                v-model="social.father_completation"
                :disabled="!allows_crud"
                :value="ec.id"
                class="mx-1"
              >
                {{ ec.value }}</b-form-checkbox
              >
            </div>
            <template v-if="!social.father_educational">
              <b-form-text text-variant="danger"
                >Debe seleccionar un Nivel Educacional.</b-form-text
              >
            </template>
            <template v-if="!social.father_completation">
              <b-form-text text-variant="danger"
                >Debe seleccionar una opción.</b-form-text
              >
            </template>
          </div>
        </div>
        <div class="row my-1">
          <!-- 3. Ocupación de la madre -->
          <div class="col">3. Ocupación de la madre</div>
          <div class="col">
            <div class="d-flex flex-row">
              <b-form-select
                :options="ocupations_types"
                v-model="$v.social.mother_ocupation.$model"
                value-field="id"
                text-field="value"
                size="sm"
                class="m-0"
                :disabled="!allows_crud"
                aria-describedby="mother_ocupation-feedback"
                :state="validateState('mother_ocupation')"
              ></b-form-select>
            </div>
            <template v-if="!social.mother_ocupation">
              <b-form-text text-variant="danger"
                >Debe seleccionar una Ocupación.</b-form-text
              >
            </template>
          </div>
        </div>
        <div class="row my-1">
          <!-- 4. Ocupación del padre -->
          <div class="col">4. Ocupación del padre</div>
          <div class="col">
            <div class="d-flex flex-row">
              <b-form-select
                :options="ocupations_types"
                v-model="$v.social.father_ocupation.$model"
                value-field="id"
                text-field="value"
                size="sm"
                class="m-0"
                :disabled="!allows_crud"
                aria-describedby="father_ocupation-feedback"
                :state="validateState('father_ocupation')"
              ></b-form-select>
            </div>
            <template v-if="!social.father_ocupation">
              <b-form-text text-variant="danger"
                >Debe seleccionar una Ocupación.</b-form-text
              >
            </template>
          </div>
        </div>
        <div class="row my-1">
          <!-- 5. ¿Tienes hermanos? -->
          <div class="col">5. ¿Tienes hermanos?</div>
          <div class="col">
            <b-form-checkbox
              v-model="social.has_siblings"
              :disabled="!allows_crud"
              switch
            ></b-form-checkbox>
          </div>
        </div>
        <div class="row my-1">
          <!-- 6. ¿Tienes hermanos que culminaron o realizan estudios superiores? -->
          <div class="col">
            6. ¿Tienes hermanos que culminaron o realizan estudios superiores?
          </div>
          <div class="col">
            <b-form-checkbox
              v-model="social.has_universitary_siblings"
              :disabled="!allows_crud"
              switch
            ></b-form-checkbox>
          </div>
        </div>
        <div class="row my-1">
          <!-- 7. Indica la o las fuentes de financiamiento de tus estudios -->
          <div class="col">
            7. Indica la o las fuentes de financiamiento de tus estudios
          </div>
          <div class="col">
            <b-form-checkbox
              v-model="social.personal_work_finance"
              :disabled="!allows_crud"
              >Trabajo Personal</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.familiar_finance"
              :disabled="!allows_crud"
              >Ingresos Familiares</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.credits_finance"
              :disabled="!allows_crud"
              >Créditos</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.scholarship_finance"
              :disabled="!allows_crud"
              >Becas</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.other_finance"
              :disabled="!allows_crud"
              >Otros, indica:</b-form-checkbox
            >
            <template v-if="social.other_finance">
              <b-form-input
                v-model="social.other_financing"
                :disabled="!allows_crud"
                size="sm"
              ></b-form-input>
            </template>
          </div>
        </div>
      </div>
      <hr />
      <strong>II-</strong> Antecedentes de salud.
      <div class="subsection">
        <strong
          >Las siguientes preguntas sobre la salud personal y familiar son
          opcionales. De considerarlo procede a contestarlas.</strong
        >
        <br />
        <strong>
          II. a Señala las enfermedades que padecen algunos miembros de tu
          familia y que te preocupan.
        </strong>
        <table>
          <thead>
            <tr>
              <th class="th-custom-width wider-screen">AFIRMACIÓN</th>
              <th><strong>Madre</strong></th>
              <th><strong>Padre</strong></th>
              <th>
                <strong style="margin-left: -1.2em !important">Hermanos</strong>
              </th>
              <th>
                <strong style="margin-left: -1em !important"
                  >Otro relevante</strong
                >
                <b-form-input
                  style="margin-left: -1em !important"
                  class="option-input"
                  v-model="social.diseases_family"
                  :disabled="!allows_crud"
                ></b-form-input>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="pl-3">Alergia</td>
              <td>
                <b-form-checkbox
                  class="ml-3"
                  v-model="social.allergy_mother"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.allergy_father"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.allergy_siblings"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.allergy_others"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pl-3">Asma</td>
              <td>
                <b-form-checkbox
                  class="ml-3"
                  v-model="social.asthma_mother"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.asthma_father"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.asthma_siblings"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.asthma_others"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pl-3">Epilepsia</td>
              <td>
                <b-form-checkbox
                  class="ml-3"
                  v-model="social.epilepsy_mother"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.epilepsy_father"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.epilepsy_siblings"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.epilepsy_others"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pl-3">Obesidad</td>
              <td>
                <b-form-checkbox
                  class="ml-3"
                  v-model="social.obesity_mother"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.obesity_father"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.obesity_siblings"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.obesity_others"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pl-3">Diabetes</td>
              <td>
                <b-form-checkbox
                  class="ml-3"
                  v-model="social.diabetes_mother"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.diabetes_father"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.diabetes_siblings"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.diabetes_others"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pl-3">Hipertensión</td>
              <td>
                <b-form-checkbox
                  class="ml-3"
                  v-model="social.hypertension_mother"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.hypertension_father"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.hypertension_siblings"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.hypertension_others"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pl-3">Cardiopatías</td>
              <td>
                <b-form-checkbox
                  class="ml-3"
                  v-model="social.heart_disease_mother"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.heart_disease_father"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.heart_disease_siblings"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.heart_disease_others"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pl-3">Tabaquismo</td>
              <td>
                <b-form-checkbox
                  class="ml-3"
                  v-model="social.smoking_mother"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.smoking_father"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.smoking_siblings"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.smoking_others"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pl-3">Alcoholismo</td>
              <td>
                <b-form-checkbox
                  class="ml-3"
                  v-model="social.alcoholism_mother"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.alcoholism_father"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.alcoholism_siblings"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.alcoholism_others"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pl-3">Drogadicción</td>
              <td>
                <b-form-checkbox
                  class="ml-3"
                  v-model="social.drug_addiction_mother"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.drug_addiction_father"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.drug_addiction_siblings"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.drug_addiction_others"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pl-3">Alguna Alteración Psíquica</td>
              <td>
                <b-form-checkbox
                  class="ml-3"
                  v-model="social.some_psychic_alteration_mother"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.some_psychic_alteration_father"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.some_psychic_alteration_siblings"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-4"
                  v-model="social.some_psychic_alteration_others"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pl-3">Otras (Especifica)</td>
              <td>
                <b-form-input
                  style="margin-left: -24%"
                  class="option-input"
                  v-model="social.more_mother"
                  :disabled="!allows_crud"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  style="margin-left: -18%"
                  class="option-input"
                  v-model="social.more_father"
                  :disabled="!allows_crud"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  style="margin-left: -13%"
                  class="option-input"
                  v-model="social.more_siblings"
                  :disabled="!allows_crud"
                ></b-form-input>
              </td>
              <td>
                <b-form-input
                  style="margin-left: -6.5%"
                  class="option-input"
                  v-model="social.more_others"
                  :disabled="!allows_crud"
                ></b-form-input>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="subsection">
        <strong>
          II. b De tu salud, señala si padeces de algunas de las siguientes
          enfermedades:
        </strong>
        <div class="row m-1 mt-2">
          <div class="col pl-4">Alergia</div>
          <div class="col">
            <b-form-checkbox
              class="pr-3"
              v-model="social.allergy_user"
              :disabled="!allows_crud"
            ></b-form-checkbox>
          </div>
        </div>
        <div class="row m-1">
          <div class="col pl-4">Asma</div>
          <div class="col">
            <b-form-checkbox
              v-model="social.asthma_user"
              :disabled="!allows_crud"
            ></b-form-checkbox>
          </div>
        </div>
        <div class="row m-1">
          <div class="col pl-4">Anemia</div>
          <div class="col">
            <b-form-checkbox
              v-model="social.anemia_user"
              :disabled="!allows_crud"
            ></b-form-checkbox>
          </div>
        </div>
        <div class="row m-1">
          <div class="col pl-4">Diabetes</div>
          <div class="col">
            <b-form-checkbox
              v-model="social.diabetes_user"
              :disabled="!allows_crud"
            ></b-form-checkbox>
          </div>
        </div>
        <div class="row m-1">
          <div class="col pl-4">Epilepsia</div>
          <div class="col">
            <b-form-checkbox
              v-model="social.epilepsy_user"
              :disabled="!allows_crud"
            ></b-form-checkbox>
          </div>
        </div>
        <div class="row m-1 mb-2">
          <div class="col pl-4">Hipertensión arterial</div>
          <div class="col">
            <b-form-checkbox
              v-model="social.hypertension_user"
              :disabled="!allows_crud"
            ></b-form-checkbox>
          </div>
        </div>
        <strong>
          Sobre tu atención médica, responde las preguntas siguientes:
        </strong>
        <div class="row m-1 mt-2">
          <div class="col">¿Tienes algún tratamiento psicológico?</div>
          <div class="col">
            <b-form-group>
              <b-form-radio-group
                id="radio-group-1"
                v-model="social.user_medical_care_psychological"
                :disabled="!allows_crud"
                name="radio-sub-component-1"
              >
                <b-form-radio value="1">No he tenido</b-form-radio>
                <b-form-radio value="2">Tuve</b-form-radio>
                <b-form-radio value="3">Tengo</b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <b-form-invalid-feedback
              id="user_medical_care_psychological-feedback"
              >Debe seleccionar una opción.</b-form-invalid-feedback
            >
          </div>
        </div>
        <div class="row m-1">
          <div class="col">¿Tienes algún apoyo psicopedagógico?</div>
          <div class="col">
            <b-form-group>
              <b-form-radio-group
                id="radio-group-2"
                v-model="social.user_medical_care_psychopedagogical"
                :disabled="!allows_crud"
                name="radio-sub-component-2"
              >
                <b-form-radio value="1">No he tenido</b-form-radio>
                <b-form-radio value="2">Tuve</b-form-radio>
                <b-form-radio value="3">Tengo</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
        <div class="row m-1">
          <div class="col">¿Tienes algún tratamiento fonoaudiológico?</div>
          <div class="col">
            <b-form-group>
              <b-form-radio-group
                id="radio-group-3"
                v-model="social.user_medical_care_speech_therapy"
                :disabled="!allows_crud"
                name="radio-sub-component-3"
              >
                <b-form-radio value="1">No he tenido</b-form-radio>
                <b-form-radio value="2">Tuve</b-form-radio>
                <b-form-radio value="3">Tengo</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
        <div class="row m-1">
          <div class="col">¿Tienes algún tratamiento psiquiátrico?</div>
          <div class="col">
            <b-form-group>
              <b-form-radio-group
                id="radio-group-4"
                v-model="social.user_medical_care_psychiatric"
                :disabled="!allows_crud"
                name="radio-sub-component-4"
              >
                <b-form-radio value="1">No he tenido</b-form-radio>
                <b-form-radio value="2">Tuve</b-form-radio>
                <b-form-radio value="3">Tengo</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
      </div>
      <hr />
      <strong> III- Relaciones con tu entorno </strong>
      <div class="subsection">
        <strong> III. a Familiar </strong>
        <div class="row m-1">
          <div class="col">
            1. ¿Con quién y dónde vas a vivir durante este período de estudios?
          </div>
          <div class="col">
            <b-form-checkbox
              v-model="social.environment_relationships_family"
              :disabled="!allows_crud"
              >Con tu familia</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.environment_relationships_family_members"
              :disabled="!allows_crud"
              >En casa de parientes</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.environment_relationships_friends"
              :disabled="!allows_crud"
              >Con amigos</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.environment_relationships_pension"
              :disabled="!allows_crud"
              >En pensión</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.environment_relationships_department"
              :disabled="!allows_crud"
              >Departamento propio o arrendado</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.environment_relationships_other"
              :disabled="!allows_crud"
              >Otra
              <b-form-input
                v-if="social.environment_relationships_other"
                placeholder="Especifique "
                v-model="social.environment_relationships_others"
                size="sm"
              ></b-form-input
            ></b-form-checkbox>
          </div>
        </div>
        <div class="row m-1">
          <div class="col">2. ¿Te sientes apoyado por tu familia?</div>
          <div class="col">
            <b-form-group>
              <b-form-radio-group
                id="radio-group-5"
                v-model="social.family_support"
                :disabled="!allows_crud"
                name="radio-sub-component-5"
              >
                <b-form-radio value="true">Si</b-form-radio>
                <b-form-radio value="false">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
        <br />
        <strong> III. b Actividades </strong>
        <br />
        <label class="mt-2">
          1. Señala las actividades que prefieres, así como aquellas en las que
          consideras tener aptitudes. <strong> (Marca al menos una)</strong>
        </label>
        <table>
          <thead>
            <tr>
              <th class="w-50 wide-screen"><strong>Actividades</strong></th>
              <th class="w-25 pl-5"><strong>Prefiero</strong></th>
              <th class="w-25 pl-5">
                <strong style="margin-left: -20%">Tengo aptitudes </strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="pl-4 custom-width">Artesanía</td>
              <td>
                <b-form-checkbox
                  class="ml-5 pl-5"
                  v-model="social.crafts_prefer"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-5 pl-5"
                  v-model="social.crafts_have_skills"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pl-4">Música</td>
              <td>
                <b-form-checkbox
                  class="ml-5 pl-5"
                  v-model="social.music_prefer"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-5 pl-5"
                  v-model="social.music_have_skills"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pl-4">Artes plásticas</td>
              <td>
                <b-form-checkbox
                  class="ml-5 pl-5"
                  v-model="social.visual_arts_prefer"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-5 pl-5"
                  v-model="social.visual_arts_have_skills"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pl-4">Danza</td>
              <td>
                <b-form-checkbox
                  class="ml-5 pl-5"
                  v-model="social.dance_prefer"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-5 pl-5"
                  v-model="social.dance_have_skills"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pl-4">Literatura</td>
              <td>
                <b-form-checkbox
                  class="ml-5 pl-5"
                  v-model="social.literature_prefer"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-5 pl-5"
                  v-model="social.literature_have_skills"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pl-4">Deporte</td>
              <td>
                <b-form-checkbox
                  class="ml-5 pl-5"
                  v-model="social.sport_prefer"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-5 pl-5"
                  v-model="social.sport_have_skills"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pl-4">Científicas</td>
              <td>
                <b-form-checkbox
                  class="ml-5 pl-5"
                  v-model="social.scientist_prefer"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-5 pl-5"
                  v-model="social.scientist_have_skills"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td class="pl-4">Teatro</td>
              <td>
                <b-form-checkbox
                  class="ml-5 pl-5"
                  v-model="social.theater_prefer"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  class="ml-5 pl-5"
                  v-model="social.theater_have_skills"
                  :disabled="!allows_crud"
                ></b-form-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <label>
          2. Indica cuál de las siguientes actividades realizas habitualmente.
        </label>
        <div class="row m-1">
          <div class="col" style="font-weight: bolder">Actividades</div>
          <div class="col" style="font-weight: bolder; margin-left: -1.5%">
            Realizo
          </div>
        </div>
        <div class="row m-1">
          <div class="col pl-4">
            Practicar deportes y/o actividades al aire libre.
          </div>
          <div class="col">
            <b-form-checkbox
              class="pl-4"
              v-model="social.do_sports"
              :disabled="!allows_crud"
            ></b-form-checkbox>
          </div>
        </div>
        <div class="row m-1">
          <div class="col pl-4">
            Ver televisión (videos, películas, música, etc.)
          </div>
          <div class="col">
            <b-form-checkbox
              v-model="social.do_watch_tv"
              :disabled="!allows_crud"
            ></b-form-checkbox>
          </div>
        </div>
        <div class="row m-1">
          <div class="col pl-4">Leer</div>
          <div class="col">
            <b-form-checkbox
              v-model="social.do_read"
              :disabled="!allows_crud"
            ></b-form-checkbox>
          </div>
        </div>
        <div class="row m-1">
          <div class="col pl-4">Escuchar música</div>
          <div class="col">
            <b-form-checkbox
              v-model="social.do_listen_music"
              :disabled="!allows_crud"
            ></b-form-checkbox>
          </div>
        </div>
        <div class="row m-1">
          <div class="col pl-4">Ver cine, teatro</div>
          <div class="col">
            <b-form-checkbox
              v-model="social.do_watch_cinema"
              :disabled="!allows_crud"
            ></b-form-checkbox>
          </div>
        </div>
        <div class="row m-1">
          <div class="col pl-4">Chatear</div>
          <div class="col">
            <b-form-checkbox
              v-model="social.do_chat"
              :disabled="!allows_crud"
            ></b-form-checkbox>
          </div>
        </div>
        <div class="row m-1">
          <div class="col pl-4">Carretear</div>
          <div class="col">
            <b-form-checkbox
              v-model="social.do_party"
              :disabled="!allows_crud"
            ></b-form-checkbox>
          </div>
        </div>
        <div class="row m-1">
          <div class="col pl-4">Practicar un culto religioso</div>
          <div class="col">
            <b-form-checkbox
              v-model="social.do_partake_religious_cult"
              :disabled="!allows_crud"
            ></b-form-checkbox>
          </div>
        </div>
        <div class="row m-1">
          <div class="col pl-4">Visitar centros culturales</div>
          <div class="col">
            <b-form-checkbox
              v-model="social.do_visit_cultural_centers"
              :disabled="!allows_crud"
            ></b-form-checkbox>
          </div>
        </div>
        <div class="row m-1">
          <div class="col pl-4">Otros</div>
          <div class="col">
            <b-form-checkbox
              v-model="social.do_other"
              :disabled="!allows_crud"
            ></b-form-checkbox>
          </div>
        </div>
      </div>
      <hr />
      <strong>IV. Tus expectativas</strong>
      <div class="subsection">
        <div class="row m-1">
          <div class="col">
            1. Selecciona la opción que consideres refleja más fielmente tu
            situación actual, con respecto a la carrera elegida.
          </div>
          <div class="col">
            <b-form-group>
              <b-form-radio-group
                id="radio-group-6"
                v-model="social.career_conformity"
                :disabled="!allows_crud"
                name="radio-sub-component-6"
                plain
                stacked
              >
                <b-form-radio value="1"
                  >Me gusta la carrera que escogí</b-form-radio
                >
                <b-form-radio value="2"
                  >No pude escoger la carrera que deseaba (selecciónala en el
                  recuadro "cuál")</b-form-radio
                >
                <b-form-radio value="3"
                  >No me disgusta la carrera, pero quisiera estudiar otra
                  (selecciónala en el recuadro "cuál")</b-form-radio
                >
                <b-form-input
                  v-if="
                    social.career_conformity == 2 ||
                    social.career_conformity == 3
                  "
                  placeholder="Especifique "
                  v-model="social.which_other_career"
                  size="sm"
                ></b-form-input>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
        <div class="row m-1">
          <div class="col">
            2. Selecciona la opción que mejor refleje el grado de atracción que
            sientes por la carrera escogida.
          </div>
          <div class="col">
            <b-form-group>
              <b-form-radio-group
                id="radio-group-7"
                v-model="social.career_expectations"
                :disabled="!allows_crud"
                name="radio-sub-component-7"
                plain
                stacked
              >
                <b-form-radio value="1"
                  >Quiero titularme, ejercer la profesión y ser un buen
                  profesional.</b-form-radio
                >
                <b-form-radio value="2"
                  >Quiero titularme y ejercer la profesión durante algún
                  tiempo.</b-form-radio
                >
                <b-form-radio value="3"
                  >Quiero titularme y después veré qué hacer.</b-form-radio
                >
                <b-form-radio value="4"
                  >Quiero titularme y luego cambiar de profesión.</b-form-radio
                >
                <b-form-radio value="5"
                  >Quiero cambiar de carrera cuando culmine el primer
                  año.</b-form-radio
                >
                <b-form-radio value="6"
                  >Aún no sé que voy a hacer en el futuro.</b-form-radio
                >
                <b-form-radio value="7">Otra situación.</b-form-radio>
                <b-form-input
                  v-if="social.career_expectations == 7"
                  placeholder="Especifique "
                  v-model="social.other_career_expectations"
                  size="sm"
                ></b-form-input>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
        <label
          >3. Si analizas tu preparación personal para enfrentar la carrera que
          elegiste, señala en qué punto de la escala te ubicarías.</label
        >
        <b-form-group>
          <b-form-radio-group
            id="radio-group-8"
            v-model="social.preparation_level"
            :disabled="!allows_crud"
            name="radio-sub-component-8"
            class="ml-5 p-3 mt-2 radiogroup"
          >
            Mínimo<span class="radio-group-label"> de preparación</span>
            <b-form-radio class="ml-3" value="1">1</b-form-radio>
            <b-form-radio value="2">2</b-form-radio>
            <b-form-radio value="3">3</b-form-radio>
            <b-form-radio value="4">4</b-form-radio>
            <b-form-radio value="5">5</b-form-radio>
            <b-form-radio value="6">6</b-form-radio>
            <b-form-radio value="7">7</b-form-radio>
            Máximo<span class="radio-group-label"> de preparación</span>
          </b-form-radio-group>
        </b-form-group>
      </div>
      <hr />
      <strong>V - Cuando estudias</strong>
      <div class="subsection">
        <label
          >1. ¿De qué manera te es más fácil estudiar?
          <strong>(Selecciona al menos siete)</strong></label
        >
        <div class="row m-1">
          <div class="col">
            <b-form-checkbox
              v-model="social.study_moving"
              :disabled="!allows_crud"
              >Desplazarte.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_singing"
              :disabled="!allows_crud"
              >Cantar.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_group"
              :disabled="!allows_crud"
              >Los estudios grupales.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_individual"
              :disabled="!allows_crud"
              >Los estudios individuales.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_making_graphics"
              :disabled="!allows_crud"
              >Realizar gráficos.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_contacting_nature"
              :disabled="!allows_crud"
              >El contacto con la naturaleza.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_making_schemes"
              :disabled="!allows_crud"
              >Realizar esquemas.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_hearing_yourself"
              :disabled="!allows_crud"
              >Oirte mientras estudias.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_acting"
              :disabled="!allows_crud"
              >Dramatizar.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_remembering_melodically"
              :disabled="!allows_crud"
              >Recordar de forma melódica</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_explained_by_classmates"
              :disabled="!allows_crud"
              >Comprender lo que me explican mis compañeros.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_establishing_objectives"
              :disabled="!allows_crud"
              >Establecer objetivos.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_underlining_important_ideas"
              :disabled="!allows_crud"
              >Subrayar las ideas más importantes.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_building_concept_maps"
              :disabled="!allows_crud"
              >Construir mapas conceptuales.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_reading_aloud"
              :disabled="!allows_crud"
              >Leer en voz alta.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_making_manualities"
              :disabled="!allows_crud"
              >Realizar trabajos manuales.</b-form-checkbox
            >
          </div>
          <div class="col">
            <b-form-checkbox
              v-model="social.study_applying_rhythms"
              :disabled="!allows_crud"
              >Aplicar ritmos para memorizar.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_teaching_classmates"
              :disabled="!allows_crud"
              >Cooperar en el aprendizaje de tus compañeros.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_highlighting_with_colors"
              :disabled="!allows_crud"
              >Utilizar colores para destacar las ideas en los
              textos.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_reflecting_about_interests"
              :disabled="!allows_crud"
              >Reflexionar según mis intereses.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_establishing_c_and_e_relationships"
              :disabled="!allows_crud"
              >Establecer relaciones causa - efecto.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_in_a_natural_setting"
              :disabled="!allows_crud"
              >Encontrarte en un medio natural.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_listening_to_recorded_classes"
              :disabled="!allows_crud"
              >Escuchar clases grabadas.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_applying_instruments"
              :disabled="!allows_crud"
              >Aplicar instrumentos.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_listening_to_music"
              :disabled="!allows_crud"
              >Escuchar música.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_communicating_what_you_learned"
              :disabled="!allows_crud"
              >Comunicar lo aprendido.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_doing_personal_projects"
              :disabled="!allows_crud"
              >Hacer proyectos a mi propio ritmo.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_having_texts_with_images"
              :disabled="!allows_crud"
              >Tener textos con imágenes.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_making_written_summaries"
              :disabled="!allows_crud"
              >Realizar resúmenes escritos.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_listening_to_recordings"
              :disabled="!allows_crud"
              >Escuchar sus grabaciones.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_experimenting_in_natural_environments"
              :disabled="!allows_crud"
              >Realizar experimentos en medios naturales.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.study_observing_animal_conduct"
              :disabled="!allows_crud"
              >Observar la conducta de los animales.</b-form-checkbox
            >
          </div>
        </div>
        <br />
        <label
          >2. ¿Cuál(es) de las siguientes condiciones te beneficia cuando
          estudias? <strong>(Selecciona al menos tres)</strong></label
        >
        <div class="row m-1">
          <div class="col">
            <b-form-checkbox
              v-model="social.preparing_to_study"
              :disabled="!allows_crud"
              >Preparar condiciones para estudiar.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.establishing_a_schedule"
              :disabled="!allows_crud"
              >Establecer un horario previamente.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.setting_time_to_each_subject"
              :disabled="!allows_crud"
              >Dividir el tiempo por asignaturas.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.using_reference_books"
              :disabled="!allows_crud"
              >Utilizar libros de consultas cuando no entiendes
              algo.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.writing_down_difficult_things"
              :disabled="!allows_crud"
              >Anotar palabras difíciles, lo que no entiendes, o algo que te
              llame la atención.</b-form-checkbox
            >
          </div>
          <div class="col">
            <b-form-checkbox
              v-model="social.using_internet_for_information"
              :disabled="!allows_crud"
              >Utilizar Internet para consultar dudas.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.organizing_ideas_before_using_them"
              :disabled="!allows_crud"
              >Organizar ideas antes de realizar un esquema.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.summarizing_what_you_studied"
              :disabled="!allows_crud"
              >Intentar resumir lo estudiado.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.asking_for_help"
              :disabled="!allows_crud"
              >Pedir ayuda cuando tienes dificultad.</b-form-checkbox
            >
            <b-form-checkbox
              v-model="social.identifying_base_ideas_when_reading"
              :disabled="!allows_crud"
              >Identificar ideas básicas cuando lees.</b-form-checkbox
            >
          </div>
        </div>
      </div>
    </div>
    <b-button size="sm m-1" v-if="allows_crud" @click="save"
      >Finalizar</b-button
    >
    <!-- Modals -->
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  name: "Social",
  components: {
    Multiselect,
    NativeLanguages: () => import("./NativeLenguages"),
  },
  mixins: [validationMixin],
  props: {
    User: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      social: {
        id: generateUniqueId(),
        user: this.User.id,
        college_type: null,
        college_region: null,
        college_commune: null,
        is_firt_time_university: null,
        is_re_entry: null,
        is_from_other_university: null,
        other_university_name: null,
        is_same_career: null,
        other_career_before: null,
        nationalities: [],
        languages: [],
        ethnicities: [],
        other_ethnicity: "",
        native_languages: [],
        mother_educational: null,
        father_educational: null,
        mother_completation: null,
        father_completation: null,
        mother_ocupation: null,
        father_ocupation: null,
        has_siblings: null,
        has_universitary_siblings: null,
        personal_work_finance: false,
        familiar_finance: false,
        credits_finance: false,
        scholarship_finance: false,
        other_finance: false,
        other_financing: "",
        diseases_family: "",
        allergy_mother: false,
        allergy_father: false,
        allergy_siblings: false,
        allergy_others: false,
        asthma_mother: false,
        asthma_father: false,
        asthma_siblings: false,
        asthma_others: false,
        epilepsy_mother: false,
        epilepsy_father: false,
        epilepsy_siblings: false,
        epilepsy_others: false,
        obesity_mother: false,
        obesity_father: false,
        obesity_siblings: false,
        obesity_others: false,
        diabetes_mother: false,
        diabetes_father: false,
        diabetes_siblings: false,
        diabetes_others: false,
        hypertension_mother: false,
        hypertension_father: false,
        hypertension_siblings: false,
        hypertension_others: false,
        heart_disease_mother: false,
        heart_disease_father: false,
        heart_disease_siblings: false,
        heart_disease_others: false,
        smoking_mother: false,
        smoking_father: false,
        smoking_siblings: false,
        smoking_others: false,
        alcoholism_mother: false,
        alcoholism_father: false,
        alcoholism_siblings: false,
        alcoholism_others: false,
        drug_addiction_mother: false,
        drug_addiction_father: false,
        drug_addiction_siblings: false,
        drug_addiction_others: false,
        some_psychic_alteration_mother: false,
        some_psychic_alteration_father: false,
        some_psychic_alteration_siblings: false,
        some_psychic_alteration_others: false,
        more_mother: "",
        more_father: "",
        more_siblings: "",
        more_others: "",
        allergy_user: false,
        asthma_user: false,
        anemia_user: false,
        diabetes_user: false,
        epilepsy_user: false,
        hypertension_user: false,
        user_medical_care_psychological: null,
        user_medical_care_psychopedagogical: null,
        user_medical_care_speech_therapy: null,
        user_medical_care_psychiatric: null,
        environment_relationships_family: false,
        environment_relationships_family_members: false,
        environment_relationships_friends: false,
        environment_relationships_pension: false,
        environment_relationships_department: false,
        environment_relationships_other: false,
        environment_relationships_others: "",
        family_support: null,
        crafts_prefer: false,
        crafts_have_skills: false,
        music_prefer: false,
        music_have_skills: false,
        visual_arts_prefer: false,
        visual_arts_have_skills: false,
        dance_prefer: false,
        dance_have_skills: false,
        literature_prefer: false,
        literature_have_skills: false,
        sport_prefer: false,
        sport_have_skills: false,
        scientist_prefer: false,
        scientist_have_skills: false,
        theater_prefer: false,
        theater_have_skills: false,
        do_sports: false,
        do_watch_tv: false,
        do_read: false,
        do_listen_music: false,
        do_watch_cinema: false,
        do_chat: false,
        do_party: false,
        do_partake_religious_cult: false,
        do_visit_cultural_centers: false,
        do_other: false,
        career_conformity: null,
        which_other_career: "",
        career_expectations: null,
        other_career_expectations: "",
        preparation_level: null,
        study_moving: false,
        study_singing: false,
        study_group: false,
        study_individual: false,
        study_making_graphics: false,
        study_contacting_nature: false,
        study_making_schemes: false,
        study_hearing_yourself: false,
        study_acting: false,
        study_remembering_melodically: false,
        study_explained_by_classmates: false,
        study_establishing_objectives: false,
        study_underlining_important_ideas: false,
        study_building_concept_maps: false,
        study_reading_aloud: false,
        study_making_manualities: false,
        study_applying_rhythms: false,
        study_teaching_classmates: false,
        study_highlighting_with_colors: false,
        study_reflecting_about_interests: false,
        study_establishing_c_and_e_relationships: false,
        study_in_a_natural_setting: false,
        study_listening_to_recorded_classes: false,
        study_applying_instruments: false,
        study_listening_to_music: false,
        study_communicating_what_you_learned: false,
        study_doing_personal_projects: false,
        study_having_texts_with_images: false,
        study_making_written_summaries: false,
        study_listening_to_recordings: false,
        study_experimenting_in_natural_environments: false,
        study_observing_animal_conduct: false,
        preparing_to_study: false,
        establishing_a_schedule: false,
        setting_time_to_each_subject: false,
        using_reference_books: false,
        writing_down_difficult_things: false,
        using_internet_for_information: false,
        organizing_ideas_before_using_them: false,
        summarizing_what_you_studied: false,
        asking_for_help: false,
        identifying_base_ideas_when_reading: false,
        closed: false,
      },
      allows_crud: true,
      college_types: [],
      educational_types: [],
      educational_completation_types: [],
      ocupations_types: [],
      regions: [],
      communes: [],
      other_institutions: [],
      nationalities: [],
      other_nationality_selected: false,
      languages: [],
      ethnicities: [],
      selected_nationalities: [],
      selected_languages: [],
      selected_ethnicities: [],
      selected_native_languages: [],
    };
  },
  validations() {
    return {
      social: {
        college_type: { required },
        college_region: { required },
        college_commune: { required },
        is_firt_time_university: { required },
        is_re_entry: { required },
        is_from_other_university: { required },
        other_university_name: {},
        is_same_career: { required },
        other_career_before: { required },
        nationalities: {},
        languages: {},
        ethnicities: {},
        other_ethnicity: {},
        native_languages: {},
        mother_educational: {},
        father_educational: {},
        mother_ocupation: {},
        father_ocupation: {},
        user_medical_care_psychological: {},
      },
    };
  },
  computed: {
    filteredCommunes() {
      let list = this.communes;
      if (this.social.college_region) {
        list = this.communes.filter(
          (x) => x.region == this.social.college_region
        );
      }
      list = list.sort(function (a, b) {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
      return list;
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.social[name];
      return $dirty ? !$error : null;
    },
    fetchSocial() {
      this.$restful
        .Get(`/cideu/social/?user=${this.User.id}`)
        .then((response) => {
          if (response.length > 0) {
            this.social = response[0];
            if (this.social.closed) {
              this.allows_crud = false;
            }
            this.setNationalities();
            this.setLanguages();
            this.setEthnicities();
          }
          if (response.length > 1)
            toast("Existe más de un formulario para este usuario.");
        });
    },
    // close() {
    //   this.$v.social.$touch();
    //   if (this.$v.social.$anyError) {
    //     this.$swal.fire("Hay errores que deben correjir.");
    //     return;
    //   }
    //   this.social.closed = true;
    //   this.save();
    // },
    deleteNativeLanguage(native_id) {
      this.$restful
        .Delete(`/cideu/native-language/${native_id}/`)
        .then(() => {});
    },
    save() {
      this.$swal({
        title: "¿Está seguro de que desea Finalizar?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$v.social.$touch();
          if (this.$v.social.$anyError) {
            this.$swal.fire("Hay errores que deben corregir!").then(() => {
              this.timeout = setTimeout(() => {
                this.$nextTick(() => {
                  let domRect = document
                    .querySelector(".input--error")
                    .getBoundingClientRect();
                  window.scrollTo(
                    domRect.left + document.documentElement.scrollLeft,
                    domRect.top + document.documentElement.scrollTop - 100
                  );
                });
              }, 500);
            });
            return;
          }
          this.social.nationalities = [];
          this.social.languages = [];
          this.social.ethnicities = [];
          this.selected_nationalities.forEach((x) => {
            this.social.nationalities.push(x.id);
          });
          this.selected_languages.forEach((x) => {
            this.social.languages.push(x.id);
          });
          this.selected_ethnicities.forEach((x) => {
            this.social.ethnicities.push(x.id);
          });
          this.social.native_languages = [];
          this.selected_native_languages.forEach((nl) => {
            if ((nl.speak && isNaN(nl.id)) || (nl.understand && isNaN(nl.id))) {
              this.$restful
                .Post(`/cideu/native-language/`, nl)
                .then((response) => {
                  nl.id = response.id;
                  this.social.native_languages.push(response.id);
                });
            } else if (
              (nl.speak && !isNaN(nl.id)) ||
              (nl.understand && !isNaN(nl.id))
            ) {
              this.$restful
                .Put(`/cideu/native-language/${nl.id}/`, nl)
                .then((response) => {
                  nl.id = response.id;
                  this.social.native_languages.push(response.id);
                });
            } else if (!nl.speak && !nl.understand && !isNaN(nl.id)) {
              this.deleteNativeLanguage(nl.id);
              nl.id = generateUniqueId();
              nl.speak = false;
              nl.understand = false;
            }
          });
          this.allows_crud = false;
          this.social.closed = true;
          this.timeout = setTimeout(() => {
            if (isNaN(this.social.id)) this.createSocial();
            else this.updateSocial();
          }, 2000);
        } else {
          return;
        }
      });
    },
    createSocial() {
      this.$restful.Post(`/cideu/social/`, this.social).then((response) => {
        this.social = response;
        toast("Formulario guardado.");
      });
    },
    updateSocial() {
      this.$restful
        .Put(`/cideu/social/${this.social.id}/`, this.social)
        .then((response) => {
          this.social = response;
          toast("Formulario actualizado.");
        });
    },
    fetchCollegeTypes() {
      this.$restful.Get(`/cideu/college-types/`).then((response) => {
        this.college_types = response;
      });
    },
    fetchEducationalTypes() {
      this.$restful.Get(`/cideu/educational-types/`).then((response) => {
        this.educational_types = response;
      });
    },
    fetchEducationalCompletationTypes() {
      this.$restful
        .Get(`/cideu/educational-completation-types/`)
        .then((response) => {
          this.educational_completation_types = response;
        });
    },
    fetchOcupationsTypes() {
      this.$restful.Get(`/cideu/ocupations-types/`).then((response) => {
        this.ocupations_types = response;
      });
    },
    fetchRegions() {
      this.$restful.Get(`/common/region/`).then((response) => {
        this.regions = response;
      });
    },
    fetchCommunes() {
      this.$restful.Get(`/common/commune/`).then((response) => {
        this.communes = response;
      });
    },
    fetchUniversities() {
      this.$restful.Get(`/common/other-institution/`).then((response) => {
        this.other_institutions = response;
      });
    },
    fetchNationalities() {
      this.$restful.Get(`/common/nationality/`).then((response) => {
        this.nationalities = response;
        this.setNationalities();
      });
    },
    fetchLanguages() {
      this.$restful.Get(`/common/language/`).then((response) => {
        this.languages = response;
        this.setLanguages();
      });
    },
    fetchEthnicities() {
      this.$restful.Get(`/common/ethnicity/`).then((response) => {
        this.ethnicities = response;
        this.setEthnicities();
      });
    },
    setNationalities() {
      this.selected_nationalities = [];
      this.social.nationalities.forEach((id) => {
        const nationality = this.nationalities.find((x) => x.id == id);
        if (nationality) this.selected_nationalities.push(nationality);
      });
    },
    // getNotionalities() {
    //   this.social.nationalities = [];
    //   this.selected_nationalities.forEach((nat) => {
    //     this.social.nationalities.push(nat.id);
    //   });
    // },
    setLanguages() {
      this.selected_languages = [];
      this.social.languages.forEach((id) => {
        const language = this.languages.find((x) => x.id == id);
        if (language) this.selected_languages.push(language);
      });
    },
    // getLanguages() {
    //   this.social.languages = [];
    //   this.selected_languages.forEach((lan) => {
    //     this.social.languages.push(lan.id);
    //   });
    // },
    setEthnicities() {
      this.selected_ethnicities = [];
      this.social.ethnicities.forEach((id) => {
        const ethnicity = this.ethnicities.find((x) => x.id == id);
        if (ethnicity) this.selected_ethnicities.push(ethnicity);
      });
    },
    // getEthnicities() {
    //   this.social.ethnicities = [];
    //   this.selected_ethnicities.forEach((eth) => {
    //     this.social.ethnicities.push(eth.id);
    //   });
    // },
    slotUpdatedNativeLanguages(native_languages) {
      this.selected_native_languages = native_languages;
    },
  },
  created() {
    this.fetchSocial();
    this.fetchCollegeTypes();
    this.fetchEducationalTypes();
    this.fetchEducationalCompletationTypes();
    this.fetchOcupationsTypes();
    this.fetchRegions();
    this.fetchCommunes();
    this.fetchUniversities();
    this.fetchNationalities();
    this.fetchLanguages();
    this.fetchEthnicities();
  },
};
</script>

<style scoped>
.section {
  text-align: justify;
}
.subsection {
  text-align: justify;
  margin: 1em 1em;
  background-color: var(--primary-color);
  padding: 1.2rem;
  border-radius: 8px;
  box-shadow: 1px 1px 7px #9cb0b1;
}
.col {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.option-input {
  width: 6rem;
  height: 1.4rem;
  font-size: 14px;
}
.custom-width {
  width: 54.15% !important;
}
.th-custom-width {
  width: 57.5%;
}
.radiogroup {
  background-color: #f2f2f2;
  max-width: fit-content;
  border-radius: 30px;
  margin-left: 6% !important;
  box-shadow: 1px 1px 5px #9cb0b1;
}
.error {
  color: red;
}
@media (min-width: 1920px) {
  .wide-screen {
    width: 60.3% !important;
  }
  .wider-screen {
    width: 67.7% !important;
  }
}
@media (max-width: 960px) {
  .radio-group-label {
    display: none;
  }
}
</style>